import router from '../../router/index';
const cipher = function (salt, text) {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

// const decipher = function (salt, encoded) {
//   const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
//   const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
//   return encoded
//     .match(/.{1,2}/g)
//     .map((hex) => parseInt(hex, 16))
//     .map(applySaltToChar)
//     .map((charCode) => String.fromCharCode(charCode))
//     .join('');
// };

const state = {
  user: {
    Id: 0,
    isAuth: false,
    Email: '',
    FirstName: '',
    LastName: ''
  },
  token: ''
};
const getters = {
  isAuth: (state) => state.user.isAuth && state.token,
  userInfo: (state) => state.user,
  getPassword: (state) => state.token
};
const mutations = {
  logIn: (state, data) => {
    state.user.isAuth = true;
    state.user.Id = data.Id;
    state.user.Email = data.Email;
    state.token = cipher(process.env.NODE_ENV, data.Password);
    state.user.FirstName = data.FirstName ?? '';
    state.user.LastName = data.LastName ?? '';
    router.push('/submissions');
  },
  logOut: (state) => {
    state.user = {
      Id: 0,
      isAuth: false,
      Email: '',
      FirstName: '',
      LastName: ''
    };
    state.token = '';
    router.push('/login');
  },
  updatePassword: (state, data) => {
    state.token = cipher(process.env.NODE_ENV, data);
  }
};
const actions = {
  async logInUser({ commit }, data) {
    commit('logIn', data);
  },
  async logOutUser(context) {
    context.commit('logOut');
  },
  async updatePassword({ commit }, data) {
    commit('updatePassword', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
