const state = {
  toast: {
    isShowed: false,
    type: '',
    message: '',
    position: ''
  },
  toasts: []
};
const getters = {
  toastOptions: (state) => state.toast,
  allToasts: (state) => state.toasts
};
const mutations = {
  showToast: (state, toast) => {
    state.toast.isShowed = true;
    state.toast.type = toast.type;
    state.toast.message = toast.message;
    state.toast.position = toast.position;

    state.toasts.push(toast);
  },
  hideToast: (state, index) => {
    state.toasts.splice(index, 1);
  },
  hideAllToast: (state) => {
    state.toasts = [];
  },
  autoHideToast: (state) => {
    state.toasts.shift();
  }
};
const actions = {
  showToast(context, data) {
    context.commit('showToast', data);
    if (!data?.isCanNotClose) {
      setTimeout(
        () => {
          context.commit('autoHideToast');
        },
        data.timeout ? data.timeout : 3500
      );
    }
  },
  hideToast(context, index) {
    context.commit('hideToast', index);
  },
  hideAllToast(context) {
    context.commit('hideAllToast');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
