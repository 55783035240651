import Vue from 'vue';
import { TablePlugin, LayoutPlugin, SpinnerPlugin, BootstrapVue, IconsPlugin, FormPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router/index';
import VueMeta from 'vue-meta';
import loader from 'vue-ui-preloader';
import BackToTop from 'vue-backtotop';
import store from './store';
import './registerServiceWorker';

Vue.use(BackToTop);
Vue.use(loader);
Vue.use(VueMeta);
Vue.use(LayoutPlugin);
Vue.use(SpinnerPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FormPlugin);
Vue.use(TablePlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  loader: loader,
  render: (h) => h(App)
}).$mount('#iso-portal');
