import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
// Store
import store from '@/store/index';

const routes = [
  {
    path: '/',
    component: () => import('@/views/MainView.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/home-page/HomePage.vue')
      },
      {
        path: '/registration',
        name: 'registration',
        meta: { guest: true },
        component: () => import('@/pages/register-page/RegisterPage.vue')
      },
      {
        path: '/login',
        name: 'login',
        meta: { guest: true },
        component: () => import('@/pages/login-page/LoginPage.vue')
      },
      {
        path: '/loginviewer',
        name: 'loginviewer',
        component: () => import('@/pages/login-admin-page/LoginAdminPage.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        meta: { requiresAuth: true },
        component: () => import('@/pages/user-page/UserPage.vue')
      },
      {
        path: '/submissions',
        name: 'submissions',
        component: () => import('@/pages/submissions-page/SubmissionsPage.vue')
      },
      {
        path: '/commissions',
        name: 'commissions',
        component: () => import('@/pages/commissions-page/CommissionsPage.vue')
      },
      {
        path: '/funded',
        name: 'funded',
        component: () => import('@/pages/funded-page/FundedPage.vue')
      },
      {
        path: '/account/:id',
        name: 'account',
        component: () => import('@/pages/account-page/AccountPage.vue')
      },
      {
        path: '/*',
        name: 'error',
        component: () => import('@/pages/404-page/404.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['user/isAuth']) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['user/isAuth']) {
      next('/');
      return;
    }
    next();
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  next();
  document.body.scrollTo(0, 0);
  window.scrollTo(0, 0);
});

export default router;
