<template>
  <div id="app">
    <loader
      v-if="isLoading"
      object="#5A02B8"
      color1="#000"
      color2="#5A02B8"
      size="10"
      speed="2"
      bg="#000"
      objectbg="#fff"
      opacity="80"
      disableScrolling="true"
      name="spinning"
    ></loader>
    <div class="toasts-container">
      <div v-for="(toast, indexToast) in allToasts" :key="indexToast">
        <ToastComponent :type="toast.type" :message="toast.message" :position="toast.position" :index="indexToast" />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ToastComponent from '@/components/main-layout/toast/Toast.vue';
export default {
  components: { ToastComponent },
  computed: {
    ...mapGetters('main', ['allToasts', 'toastOptions'])
  },
  data() {
    return {
      isLoading: true
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      this.isLoading = document.readyState === 'complete' ? false : true;
    };
  }
};
</script>
<style lang="scss">
.toasts-container {
  position: absolute;
  z-index: 99999;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: fit-content;
}
</style>
