var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toaster",class:_vm.setPossitionClass},[_c('div',{staticClass:"slds-notify slds-notify_toast",class:{
      'slds-theme_success': _vm.type === 'success',
      'slds-theme_warning': _vm.type === 'warning',
      'slds-theme_error': _vm.type === 'error',
      'slds-theme_info': _vm.type === 'info',
      'slds-theme_info toaster__refresh': _vm.type === 'refresh'
    },attrs:{"role":"status"}},[(_vm.type !== 'refresh')?_c('span',{staticClass:"slds-icon_container slds-m-right_small slds-no-flex slds-align-top",class:{
        'slds-icon-utility-success': _vm.type === 'success',
        'slds-icon-utility-warning': _vm.type === 'warning',
        'slds-icon-utility-error': _vm.type === 'error',
        'slds-icon-utility-info': _vm.type === 'refresh'
      }},[_c('svg',{staticClass:"slds-icon slds-icon_small",attrs:{"aria-hidden":"true"}},[(_vm.type === 'success')?[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#success"}})]:_vm._e(),(_vm.type === 'error')?[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#error"}})]:_vm._e(),(_vm.type === 'warning')?[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#warning"}})]:_vm._e(),(_vm.type === 'info')?[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#info"}})]:_vm._e()],2)]):(_vm.type === 'refresh')?[_vm._m(0)]:_vm._e(),_c('div',{staticClass:"slds-notify__content"},[_c('h2',{staticClass:"slds-text-heading_small toaster__message"},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('div',{staticClass:"slds-notify__close"},[_c('button',{staticClass:"slds-button slds-button_icon slds-button_icon-inverse",attrs:{"title":"Close"},on:{"click":function($event){return _vm.onToastCloseClick(_vm.index)}}},[_c('svg',{staticClass:"slds-button__icon slds-button__icon_large",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#close"}})])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slds-spinner slds-spinner_inverse slds-spinner_small",attrs:{"role":"status"}},[_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Loading")]),_c('div',{staticClass:"slds-spinner__dot-a"}),_c('div',{staticClass:"slds-spinner__dot-b"})])
}]

export { render, staticRenderFns }