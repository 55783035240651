<template>
  <div class="toaster" :class="setPossitionClass">
    <div
      class="slds-notify slds-notify_toast"
      :class="{
        'slds-theme_success': type === 'success',
        'slds-theme_warning': type === 'warning',
        'slds-theme_error': type === 'error',
        'slds-theme_info': type === 'info',
        'slds-theme_info toaster__refresh': type === 'refresh'
      }"
      role="status"
    >
      <span
        v-if="type !== 'refresh'"
        class="slds-icon_container slds-m-right_small slds-no-flex slds-align-top"
        :class="{
          'slds-icon-utility-success': type === 'success',
          'slds-icon-utility-warning': type === 'warning',
          'slds-icon-utility-error': type === 'error',
          'slds-icon-utility-info': type === 'refresh'
        }"
      >
        <svg class="slds-icon slds-icon_small" aria-hidden="true">
          <template v-if="type === 'success'">
            <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#success" />
          </template>
          <template v-if="type === 'error'">
            <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#error" />
          </template>
          <template v-if="type === 'warning'">
            <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#warning" />
          </template>
          <template v-if="type === 'info'">
            <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#info" />
          </template>
        </svg>
      </span>
      <template v-else-if="type === 'refresh'">
        <div class="slds-spinner slds-spinner_inverse slds-spinner_small" role="status">
          <span class="slds-assistive-text">Loading</span>
          <div class="slds-spinner__dot-a" />
          <div class="slds-spinner__dot-b" />
        </div>
      </template>
      <div class="slds-notify__content">
        <h2 class="slds-text-heading_small toaster__message">
          {{ message }}
        </h2>
      </div>
      <div class="slds-notify__close">
        <button
          class="slds-button slds-button_icon slds-button_icon-inverse"
          title="Close"
          @click="onToastCloseClick(index)"
        >
          <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
            <use xlink:href="@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg#close" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ToastComponent',
  props: {
    message: {
      type: String,
      default: 'Description'
    },
    type: {
      type: String,
      default: 'success'
    },
    position: {
      type: String,
      default: 'top',
      requried: false
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number
    }
  },
  computed: {
    setPossitionClass() {
      return {
        'position-top': this.position === 'top',
        'position-bottom-left': this.position === 'bottom-left'
      };
    }
  },
  methods: {
    ...mapActions('main', ['hideToast']),
    onToastCloseClick(index) {
      this.hideToast(index);
    }
  }
};
</script>

<style lang="scss">
@import '~@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css';
.toaster {
  // position: absolute !important;
  width: fit-content !important;
  z-index: 99999 !important;
  &__refresh {
    color: #fff !important;
    background-color: #161616 !important;
    min-width: 180px !important;

    .slds-spinner {
      position: static !important;
      margin-right: 0.5rem !important;
    }
  }
  &__message {
    color: #fff !important;
  }
}

.position-top {
  left: 0 !important;
  right: 0 !important;
  top: 55px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.position-bottom-left {
  position: fixed;
  bottom: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 20px !important;
}
</style>
