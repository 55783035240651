import Vue from 'vue';
import Vuex from 'vuex';
import user from './user/user';
import main from './main/main';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    main
  },
  plugins: [createPersistedState()]
});
